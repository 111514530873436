import { Box, Container, Flex, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { ReactNode } from "react"
import { Logo64 } from "components/Logo"
import { LinkButton } from "components/LinkButton"

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  )
}

// todo fix footer with about us
export default function LargeWithLogoCentered() {
  return (
    <Box bg={useColorModeValue("white", "gray.800")} color={useColorModeValue("gray.700", "gray.200")}>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
          <LinkButton backgroundColor={useColorModeValue("white", "gray.800")} href="/tos">
            Terms of Service
          </LinkButton>
          <LinkButton backgroundColor={useColorModeValue("white", "gray.800")} href="/aboutus">
            About
          </LinkButton>

          <LinkButton
            backgroundColor={useColorModeValue("white", "gray.800")}
            href="https://pool.pm/07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d"
          >
            Pool.pm
          </LinkButton>
          <LinkButton
            backgroundColor={useColorModeValue("white", "gray.800")}
            href="https://pooltool.io/pool/07c3280a92a3010a1713e3cb22bfa82624a33061ad20c7b359e7252d/manage"
          >
            PoolTool.io
          </LinkButton>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo64 />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2021 RoamVerse.
        </Text>
      </Box>
    </Box>
  )
}
