import * as React from "react"
import { BiMoon, BiSun } from "react-icons/bi"
import { GiHamburgerMenu } from "react-icons/gi"
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { useLogout } from "lib/hooks/useLogout"
import { useMe } from "lib/hooks/useMe"

import { Limiter } from "./Limiter"
import { LinkButton } from "./LinkButton"
import { Logo64 } from "components/Logo"
import { BsDiscord, BsTelegram, BsTwitter } from "react-icons/bs"

export function Header() {
  const { me, loading } = useMe()
  const logout = useLogout()
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === "dark"

  return (
    <Box
      w="100%"
      pos="fixed"
      top={0}
      left={0}
      borderColor={useColorModeValue("gray.100", "gray.700")}
      zIndex={500}
    >
      <Limiter
        display="flex"
        transition="100ms all"
        bg={useColorModeValue("white", "gray.800")}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        {/* Left link list */}
        <HStack p={0}>
          <HomeLink
            href="/"
            // color={useColorModeValue("purple.600", "purple.400")}
            pl={0}
            // textTransform="uppercase"
            // fontWeight="bold"
          >
            <HStack>
              <Logo64 />
            </HStack>
          </HomeLink>

          <LinkButton backgroundColor={useColorModeValue("white", "gray.800")} href="/nfts">
            NFTs
          </LinkButton>
          <LinkButton backgroundColor={useColorModeValue("white", "gray.800")} href="/rollout">
            Rollout
          </LinkButton>
        </HStack>

        {/* Right link list */}

        {/*{!me && !loading && (*/}
        {/*  <Fade in>*/}
        {/*    <HStack spacing={4} display={{ base: "none", md: "flex" }}>*/}
        {/*      <LinkButton href="/login" variant="ghost">*/}
        {/*        Login*/}
        {/*      </LinkButton>*/}
        {/*      <LinkButton href="/register" variant="solid" colorScheme="purple">*/}
        {/*        Register*/}
        {/*      </LinkButton>*/}
        {/*    </HStack>*/}
        {/*  </Fade>*/}
        {/*)}*/}
        {/*{me && <Fade in>Hello, {me.firstName}!</Fade>}*/}
        <Box flex={1} />
        {/* Right menu list */}

        <HStack placement="bottom-end">
          <LinkButton
            backgroundColor={useColorModeValue("white", "gray.800")}
            href={"https://twitter.com/RoamPool"}
          >
            <Box as={BsTwitter} />
          </LinkButton>
          <LinkButton
            backgroundColor={useColorModeValue("white", "gray.800")}
            href={"https://discord.gg/h8JZ8DCcEK"}
          >
            <Box as={BsDiscord} />
          </LinkButton>
          <LinkButton
            backgroundColor={useColorModeValue("white", "gray.800")}
            href={"https://t.me/roam_pool_channel"}
          >
            <Box as={BsTelegram} />
          </LinkButton>
        </HStack>

        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            display={"flex"}
            // display={{ base: "flex", md: me ? "flex" : "none" }}
            variant="ghost"
            borderRadius="full"
            icon={me ? <Avatar size="xs" src={me.avatar || undefined} /> : <Box as={GiHamburgerMenu} />}
          />

          <MenuList fontSize="md">
            <MenuItem
              closeOnSelect={false}
              icon={<Box as={isDark ? BiSun : BiMoon} boxSize="16px" />}
              onClick={toggleColorMode}
            >
              {isDark ? "Lights on" : "Lights off"}
            </MenuItem>

            {/*{me ? (*/}
            {/*  <>*/}
            {/*    <NextLink passHref href="/profile">*/}
            {/*      <MenuItem icon={<Box as={BiUser} boxSize="16px" />}>Profile</MenuItem>*/}
            {/*    </NextLink>*/}
            {/*    {me.role === Role.Admin && (*/}
            {/*      <NextLink passHref href="/admin">*/}
            {/*        <MenuItem icon={<Box as={BiCog} boxSize="16px" />}>Admin</MenuItem>*/}
            {/*      </NextLink>*/}
            {/*    )}*/}
            {/*    <MenuDivider />*/}
            {/*    <MenuItem*/}
            {/*      closeOnSelect={false}*/}
            {/*      icon={<Box as={isDark ? BiSun : BiMoon} boxSize="16px" />}*/}
            {/*      onClick={toggleColorMode}*/}
            {/*    >*/}
            {/*      Toggle theme*/}
            {/*    </MenuItem>*/}
            {/*    <MenuDivider />*/}
            {/*    <MenuItem onClick={() => logout()} icon={<Box as={BiExit} boxSize="16px" />}>*/}
            {/*      Logout*/}
            {/*    </MenuItem>*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <>*/}
            {/*    <MenuItem*/}
            {/*      closeOnSelect={false}*/}
            {/*      icon={<Box as={isDark ? BiSun : BiMoon} boxSize="16px" />}*/}
            {/*      onClick={toggleColorMode}*/}
            {/*    >*/}
            {/*      Toggle theme*/}
            {/*    </MenuItem>*/}
            {/*    <MenuDivider />*/}
            {/*    <NextLink passHref href="/login">*/}
            {/*      <MenuItem>Login</MenuItem>*/}
            {/*    </NextLink>*/}
            {/*    <NextLink passHref href="/register">*/}
            {/*      <MenuItem fontWeight="semibold">Register</MenuItem>*/}
            {/*    </NextLink>*/}
            {/*  </>*/}
            {/*)}*/}
          </MenuList>
        </Menu>
      </Limiter>
    </Box>
  )
}

interface HomeLinkProps extends LinkProps {
  href: string
}

function HomeLink({ href, ...props }: HomeLinkProps) {
  const { asPath } = useRouter()
  const isActive = asPath === href

  return (
    <NextLink passHref href={href}>
      <Link
        px={4}
        py={2}
        textDecor="none !important"
        _hover={{ color: isActive ? "purple.600" : "purple.500" }}
        color={isActive ? "purple.600" : "gray.500"}
        {...props}
      >
        {props.children}
      </Link>
    </NextLink>
  )
}
