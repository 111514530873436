import * as React from "react"
import { Image, useColorMode } from "@chakra-ui/react"

export const OldLogo = ({ ...props }) => {
  return <Image src="https://roampool.com/logo-old.png" alt="Logo" {...props} />
}

export const Logo64 = ({ ...props }) => {
  return <Image alt="Logo64" src={"https://roampool.com/icon_64x64.png"} {...props} />
}

export const RoamLettersLight = ({ ...props }) => {
  return <Image alt="RoamLettersLight" h={8} src={"https://roampool.com/ROAM-letters-light.png"} {...props} />
}

export const RoamLettersDark = ({ ...props }) => {
  return <Image alt={"RoamLettersDark"} h={8} src={"https://roampool.com/ROAM-letters-dark.png"} {...props} />
}

export const RoamLetters = ({ ...props }) => {
  const { colorMode } = useColorMode()
  const isDark = colorMode === "dark"
  return <>{isDark ? <RoamLettersLight {...props} /> : <RoamLettersDark {...props} />}</>
}
